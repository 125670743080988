import axios from 'axios';
import { Toast } from '../../toast';

const http = axios.create({
  baseURL: '/will/api/v1',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content'),
  },
});

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 404) {
      return Promise.reject({
        type: 'not_found',
        message: 'The requested record was not found.',
      });
    }

    if (error.response?.status === 422) {
      return Promise.reject({
        type: 'validation',
        message: 'Whoops… Something went wrong.',
        fields: error.response?.data?.errors,
        state: error.response?.data?.data,
      });
    }

    if (error.response?.data?.message) {
      return Promise.reject({
        type: 'server',
        message: error.response?.data?.message,
      });
    }

    Toast.$emit('toastShow', {
      message: error?.message || 'Whoops... Something went wrong.',
      type: 'error',
    });

    return Promise.reject({
      type: 'other',
      message: error.message,
      error,
    });
  }
);

export default http;
